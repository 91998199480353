import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import customerReducer from "./ducks/customer/";
import enterprisesReducer from "./ducks/enterprises";
import currentEnterpriseReducer from "./ducks/enterprise";
import citiesReducer from "./ducks/cities";
import professionsReducer from "./ducks/professions";
import taxpayerReducer from "./ducks/taxpayer";
import banksReducer from "./ducks/banks";
import contractsReducer from "./ducks/contracts";
import ufsReducer from "./ducks/ufs";
import batchReducer from "./ducks/batch";
import customersReducer from "./ducks/customers";
import messagexBoxesReducer from "./ducks/message-boxes";
import companyInformationReducer from "./ducks/company-information";

const reducers = combineReducers({
  customer: customerReducer,
  enterprises: enterprisesReducer,
  currentEnterprise: currentEnterpriseReducer,
  contracts: contractsReducer,
  cities: citiesReducer,
  professions: professionsReducer,
  taxpayers: taxpayerReducer,
  banks: banksReducer,
  ufs: ufsReducer,
  batch: batchReducer,
  customers: customersReducer,
  messagesBoxes: messagexBoxesReducer,
  companyInformation: companyInformationReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["contracts", "companyInformation", "enterprises"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
