import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ExternalLayout_Enterprise from "../../layouts/ExternalLayout-Enterprise";
import { getById } from "../../store/ducks/enterprise";
import { Button,P} from "../../components/generic";
import EnterpriseMapCard from "../../components/card-enterprise-map";
import useGetCustomer from "../../hooks/customer";
import { Col, Row } from "react-bootstrap";
import { CustomCard, Header_Enterprise} from "./style";
import ReactHtmlParser from "react-html-parser";


const getCurrentEnterprise = (enterprises, id) => {
  return enterprises.find((enterprise) => enterprise.id == id);
};

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#153d77", borderRadius: '100px' }}
      onClick={onClick}
    />
  );
}


const EnterpriseMap = () => {
  const history = useHistory();
  const { id } = useParams();


  const enterprises = useSelector((state) => {
    return state.enterprises;
  });

  const enterprise = useSelector((state) => {
    return state.currentEnterprise;
  });

  const dispatch = useDispatch();

  const { customerId } = useGetCustomer();

  useEffect(() => {
    const getEnterprise = () => {
      dispatch(getById({ id, customerId: customerId || 0 }));
    };
    getEnterprise();
  }, [dispatch]);

  console.log("enterprise", enterprise);
  return (
    <>
        <ExternalLayout_Enterprise>
          <Header_Enterprise>
            <Row>
              <Col
                xs={12}
                  className="d-flex justify-content-end align-items-center"
                >
                <Button
                  onClick={() => history.push("/inicio")}
                  className="btn new-header__button-login link-primary link--norma btn-header"
                  >
                  <i className="align-self-center me-2"></i>
                  Voltar
                </Button>
              </Col>
            </Row>
          </Header_Enterprise>            
            
          <CustomCard>                    
            <div id="mapa"> 
              <P className="block-with-text">{ReactHtmlParser(getCurrentEnterprise(enterprises, id)?.publicityBanner)}</P>                   
              <EnterpriseMapCard {...enterprise}/>                           
            </div> 
          </CustomCard> 

        </ExternalLayout_Enterprise>
    </>
  );
};

export default EnterpriseMap;
