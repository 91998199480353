import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getBankNearestByDescription = createAsyncThunk(
  "banks/getBankNearestByDescription",
  async (nearestDescription) => {
    const { data } = await callAPI({ method: 'GET', url: `banks/nearest/${nearestDescription}`});
    return data;
  }
);

const INITIAL_STATE = [{ label: "", id: undefined }];

export const bankSlice = createSlice({
  name: "banks",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getBankNearestByDescription.fulfilled]: (state, { payload }) => {
      return payload.map((bank) => {
        return {
          label: bank.description,
          id: bank.id,
        };
      });
    },
  },
});

export default bankSlice.reducer;
