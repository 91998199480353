import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import callAPI from "../../../api";
import { getBase64 } from "../../../utils/file";
import formatErrorMessage from "../../../utils/message-error";

export const getCustomerById = createAsyncThunk(
  "customer/getCustomerById",
  async (customerId) => {
    const { data } = await callAPI({method: 'GET', url: `customers/${customerId}`});
    return data;
  }
);

export const getCustomerLocalizationsByCustomerId = createAsyncThunk(
  "customer/getCustomerLocalizationsByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/localizations`});
    return data;
  }
);

export const getDependsByCustomerId = createAsyncThunk(
  "customer/getDependsByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/dependents`});
    return data;
  }
);

export const getContactsByCustomerId = createAsyncThunk(
  "customer/getContactsByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/contacts`});
    return data;
  }
);

export const getBanksByCustomerId = createAsyncThunk(
  "customer/getBanksByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/banks`});
    return data;
  }
);

export const getPatrimoniesByCustomerId = createAsyncThunk(
  "customer/getPatrimoniesByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/patrimonies`});
    return data;
  }
);

export const putCustomerById = createAsyncThunk(
  "customer/putCustomerById",
  async ({ customer, callbackSuccess, callbackError }) => {
    const { customerId, payload } = customer;
    try {
      const payloadToResquest = {
        ...payload,
        professionId: payload?.profession?.id,
        birthplaceId: payload?.birthplace?.id,
        file: payload.file ? { content: await getBase64(payload.file), name: payload.file.name } : null
      };
      await callAPI({ method: 'PUT', url: `customers/${customerId}`, data: payloadToResquest});
      callbackSuccess();
      return payload;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const deleteLocalizationById = createAsyncThunk(
  "customer/deleteLocalizationById",
  async ({ localizationId, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'DELETE', url: `localizations/${localizationId}`});
      callbackSuccess();
      return localizationId;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const deleteDependentById = createAsyncThunk(
  "customer/deleteDependentById",
  async ({ dependentId, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'DELETE', url: `dependents/${dependentId}`});
      callbackSuccess();
      return dependentId;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const deletePatrimonyById = createAsyncThunk(
  "customer/deletePatrimonyById",
  async ({ patrimonyId, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'DELETE', url: `patrimonies/${patrimonyId}`});
      callbackSuccess();
      return patrimonyId;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const deleteContactById = createAsyncThunk(
  "customer/deleteContactById",
  async ({ contactId, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'DELETE', url: `contacts/${contactId}`});
      callbackSuccess();
      return contactId;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const deleteBankById = createAsyncThunk(
  "customer/deleteBankById",
  async (bankId) => {
    await callAPI({ method: 'DELETE', url: `customerbanks/${bankId}`});
    return bankId;
  }
);

export const addContact = createAsyncThunk(
  "customer/addContact",
  async ({ payload, callbackError, callbackSuccess }) => {
    try {
      const { data } = await callAPI({ method: 'POST', url: "customers/contacts/", data: payload});
      callbackSuccess()("Contato adicionado com sucesso !");
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const addLocalization = createAsyncThunk(
  "customer/addLocalization",
  async ({ localization, callbackError, callbackSuccess }) => {
    const payloadToResquest = {
      ...localization,
      taxpayerTypeId: localization.taxpayerType.id,
      cityId: localization.city.id,
    };
    try {
      const { data } = await callAPI({ method: 'POST', url: `customers/localizations/`, data: {
        ...payloadToResquest,
      }});
      callbackSuccess()("Localização incluida com sucesso !");
      return { ...localization, id: data.id };
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const updatePatrimonyById = createAsyncThunk(
  "customer/updatePatrimonyById",
  async ({ patrimony, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'PUT', url: `patrimonies/${patrimony.id}`, data: {
        ...patrimony,
        cityId: patrimony?.city?.id,
      }});
      callbackSuccess()("Patrimônio atualizado com sucesso !");
      return patrimony;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const addPatrimony = createAsyncThunk(
  "customer/addPatrimony",
  async ({ patrimony, customerId, callbackError, callbackSuccess }) => {
    try {
      const { data } = await callAPI({ method: 'POST', url: `customers/patrimonies/`, data: {
        ...patrimony,
        cityId: patrimony?.city?.id,
        customerId,
      }});
      callbackSuccess()("Patrimônio adicionado com sucesso !");
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const addDependent = createAsyncThunk(
  "customer/addDependent",
  async ({ dependent, callbackError, callbackSuccess }) => {
    try {
      const { data } = await callAPI({ method: 'POST', url: `customers/dependents/`, data: {
        ...dependent,
      }});
      callbackSuccess();
      return { ...dependent, id: data.id };
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const addBank = createAsyncThunk(
  "customer/addBank",
  async ({ bank, customerId, callbackError, callbackSuccess }) => {
    try {
      const payloadToRequest = {
        bankId: bank.bank.id,
        customerId,
        ...bank,
      };
      const { data } = await callAPI({ method: 'POST', url: `customers/banks/`, data: payloadToRequest});
      callbackSuccess();
      return { ...data, bank: bank.bank };
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const updateContact = createAsyncThunk(
  "customer/updateContact",
  async ({ contact, callbackError, callbackSuccess }) => {
    try {
      await callAPI({ method: 'PUT', url: `contacts/${contact.id}`, data: {
        ...contact,
        situation: isEqual("Ative", contact.situation),
      }});
      callbackSuccess()("Contato atualizado com sucesso !");
      return contact;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const updateLocalization = createAsyncThunk(
  "customer/updateLocalization",
  async ({ localization, callbackError, callbackSuccess }) => {
    const payloadToResquest = {
      ...localization,
      taxpayerTypeId: localization.taxpayerType.id,
      cityId: localization.city.id,
    };
    try {
      await callAPI({ method: 'PUT', url: `localizations/${localization.id}`, data: payloadToResquest});
      callbackSuccess()("Localização atualizada com sucesso !");
      return localization;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

const INITIAL_STATE = {
  localizations: [],
  dependents: [],
  contacts: [],
  banks: [],
  patrimonies: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: INITIAL_STATE,
  reducers: {
    setSuccessUndefined: (state) => {
      return { ...state, success: undefined };
    },
  },
  extraReducers: {
    [getCustomerById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        rgUf: { label: payload?.rgUf?.name, id: payload?.rgUf?.id },
      };
    },
    [putCustomerById.fulfilled]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [putCustomerById.rejected]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [deleteDependentById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        dependents: state.dependents.filter(
          (dependent) => dependent.id !== payload
        ),
      };
    },
    [getCustomerLocalizationsByCustomerId.fulfilled]: (state, { payload }) => {
      const localizations = payload.map((localization) => {
        return {
          ...localization,
          city: {
            label: localization.city.name,
            id: localization.city.id,
            uf: localization.city.uf.name,
          },
        };
      });
      return { ...state, localizations: [...localizations] };
    },
    [getDependsByCustomerId.fulfilled]: (state, { payload }) => {
      return { ...state, dependents: [...payload] };
    },
    [getPatrimoniesByCustomerId.fulfilled]: (state, { payload }) => {
      return { ...state, patrimonies: [...payload] };
    },
    [getContactsByCustomerId.fulfilled]: (state, { payload }) => {
      return { ...state, contacts: [...payload] };
    },
    [getBanksByCustomerId.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        banks: [
          ...payload.map((bank) => {
            return { ...bank, bank: { label: bank.bankName, id: bank.bankId } };
          }),
        ],
      };
    },
    [deleteContactById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        contacts: state.contacts.filter((element) => element.id !== payload),
      };
    },
    [deleteBankById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        banks: state.banks.filter((element) => element.id !== payload),
      };
    },
    [deletePatrimonyById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        patrimonies: [
          ...state.patrimonies.filter((element) => element.id !== payload),
        ],
      };
    },
    [deleteLocalizationById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        localizations: [
          ...state.localizations.filter((element) => element.id !== payload),
        ],
      };
    },
    [updateLocalization.fulfilled]: (state, { payload }) => {
      // return {
      //   ...state, localizations: [...state.localizations.map(localization => localization.id === payload.id ? payload : localization)],
      // };
      return { ...state };
    },
    [addContact.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        contacts: state.contacts.concat(payload),
      };
    },
    [addBank.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        banks: state.banks.concat({
          ...payload,
        }),
      };
    },
    [addContact.rejected]: (state, { payload }) => {
      return {
        ...state,
      };
    },
    [addPatrimony.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        patrimonies: state.patrimonies.concat(payload),
      };
    },
    [addLocalization.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        localizations: state.localizations.concat([payload]),
      };
    },
    [addLocalization.rejected]: (state, { payload }) => {
      return { ...state };
    },
    [updateContact.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          isEqual(contact.id, payload.id) ? { ...payload } : contact
        ),
      };
    },
    [updateContact.rejected]: (state, { payload }) => {
      return {
        ...state,
      };
    },
    [updatePatrimonyById.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        patrimonies: state.patrimonies.map((patrimony) =>
          patrimony.id === payload.id ? payload : patrimony
        ),
      };
    },
    [addDependent.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        dependents: state.dependents.concat(payload),
      };
    },
  },
});

export default customerSlice.reducer;

export const { setSuccessUndefined } = customerSlice.actions;
