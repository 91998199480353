import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./theme/globalStyles";
import { useTheme } from "./theme/useTheme";
import { Provider } from "react-redux";
import store from "./store";
import RoutersApp from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CookiesProvider } from "react-cookie";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

let persistor = persistStore(store);

const Container = styled.div`
  margin: 0px auto;
  overflow: auto;
`;

function App() {
  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded]);

  return (
    <>
    <CookiesProvider>
      <ToastContainer position="top-center"/>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <Container style={{ fontFamily: selectedTheme.font }}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <RoutersApp />
              </PersistGate>
            </Provider>
          </Container>
        </ThemeProvider>
      )}
    </CookiesProvider>
    </>
  );
}

export default App;
