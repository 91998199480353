import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";
import formatErrorMessage from "../../../utils/message-error";

export const getContractsByCustomerId = createAsyncThunk(
  "contracts/getContractsByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({ method: 'GET', url: `customers/${customerId}/contracts`});
    return data;
  }
);

export const installmentsbanksliprequest = createAsyncThunk(
  "contracts/installmentsbanksliprequest",
  async ({ bankSlipRequest, callbackSuccess, callbackError }) => {
    try {
      const { data } = await callAPI({ method: 'POST', url:
        `installments/banksliprequest`,
        data: bankSlipRequest }
      );
      callbackSuccess()("Solicitação para gerar boleto enviada com sucesso !")
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const getInstallmentsByContractId = createAsyncThunk(
  "contracts/getInstallmentsByContractId",
  async (contractId) => {
    const { data } = await callAPI({ method: 'GET', url: `contracts/${contractId}/installments`});
    return { installments: data, contractId };
  }
);

export const getBrokersByContractId = createAsyncThunk(
  "contracts/getBrokersByContractId",
  async (contractId) => {
    const { data } = await callAPI({ method: 'GET', url: `contracts/${contractId}/brokers`});
    return { brokers: data, contractId };
  }
);

export const getContractDetailsByContractId = createAsyncThunk(
  "contracts/getContractDetailsByContractId",
  async (contractId) => {
    const { data } = await callAPI({ method: 'GET', url: `contracts/${contractId}/details`});
    return data;
  }
);

const INITIAL_STATE = [
  {
    identifier: "",
    description: "",
    situation: "",
    brokers: [],
    installments: [],
    detail: {},
  },
];

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getContractsByCustomerId.fulfilled]: (state, { payload }) => {
      return payload.map((contract) => {
        return { ...contract, enterpriseName: contract.enterprise };
      });
    },
    [getContractDetailsByContractId.fulfilled]: (state, { payload }) => {
      return state.map((contract) => {
        return contract.id === payload.id
          ? { ...contract, ...payload }
          : { ...contract };
      });
    },
    [getInstallmentsByContractId.fulfilled]: (state, { payload }) => {
      return state.map((contract) => {
        return contract.id == payload.contractId
          ? {
              ...contract,
              installments: payload.installments.map((installment) => {
                return { ...installment };
              }),
            }
          : { ...contract };
      });
    },
    [getBrokersByContractId.fulfilled]: (state, { payload }) => {
      return state.map((contract) => {
        return contract.id == payload.contractId
          ? { ...contract, brokers: payload?.brokers }
          : { ...contract };
      });
    },
  },
});

export default contractsSlice.reducer;
