import { I } from "../../components/generic";

const formatErrorMessage = (error) => {
  if(error.response.status !== 404){
    const errorMessage = error.response.data.message;
    const messageFormaterd = errorMessage.substring(errorMessage.indexOf("[\"") +2 , errorMessage.lastIndexOf("\"]"))
    return messageFormaterd
  } else {
    return error.response.data.message;
  }
}

export const formatErrorMessageAsString = (errorString) => {
  return errorString?.substring(
    errorString.indexOf("]: [") + 6,
    errorString.lastIndexOf('"]') - 1
  );
};
export default formatErrorMessage;
