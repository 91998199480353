import styled from "styled-components";

export const CustomCard = styled.div`  
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;  
  background-color: var(--bs-white);  
  margin-bottom: 0.5rem;
`
export const Header_Enterprise = styled.div`
  position: relative;
  height: 43px;
`


