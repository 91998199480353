import styled from "styled-components";

export const MapContent = styled.div`
  height: 650px;
  position: relative;
  overflow: hidden;
`;

export const CustomCard = styled.div`
  box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--bs-white);
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
`
