import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ExternalLayout from "../../layouts/ExternalLayout";
import { getById } from "../../store/ducks/batch";
import { Button, Div, H1, H3 } from "../../components/generic";
import { Formik } from "formik";
import { Form, Container, Row, Col, Card, Alert } from "react-bootstrap";

const EnterpriseDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const batch = useSelector((state) => {
    return state.batch;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getBatch = () => {
      dispatch(getById(id));
    };

    getBatch();
  }, [dispatch]);

  return (
    <ExternalLayout>
      <Div className="header">
        <Container fluid>
          <Row>
            <Col xs="11">
              <H1 className="header-title">
                Quadra: {batch?.block} Lote: {batch?.batch}
              </H1>
              <h3 className="header-subtitle">{batch?.description}</h3>
            </Col>
            <Col xs="1">
              <Button onClick={history.goBack}>
                <i className="align-self-center me-2 fas fa-fw fa-arrow-left"></i>
                Voltar
              </Button>
            </Col>
          </Row>
        </Container>
      </Div>
      <Row>
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Card.Title>
                  <H3 className="card-subtitle mb-2 text-muted">
                    Envie as informações abaixo que entramos em contato, para
                    sua comodidade.
                  </H3>
                </Card.Title>
              </Row>
              <Formik
                initialValues={{
                  firstName: "Marques",
                  lastName: "Woodson",
                }}
                render={({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                }) => (
                  <Row>
                    <Row>{errors && <Alert variant="danger"></Alert>}</Row>
                    <Row>
                      <Form className="mx-auto">
                        <Form.Group className="mb-3" controlId="formName">
                          <Form.Label>Nome :</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Nome completo"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                          <Form.Label>Email :</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPhone">
                          <Form.Label>Telefone :</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Telefone"
                          />
                        </Form.Group>
                        <Row>
                          <Col xs="11"></Col>
                          <Col xs="1">
                            <Button className="btn btn-primary" type="submit">
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </Row>
                )}
              />
            </Container>
          </Card.Body>
        </Card>
      </Row>
    </ExternalLayout>
  );
};

export default EnterpriseDetails;
