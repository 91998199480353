import getMapOptions from "./map-options";
import loader from "./loader";
import getMarkers from "./markers";
import CustomOverlay from "./custom-overlay";
import initMarkerClusterer from "./marker-clusterer";

export {
  getMapOptions
}

const CustomMap = {

  getInstance: (image, sw, ne, rotation, zoom, locations) =>
    loader
      .load()
      .then( async (google) => {

        const map = new google.maps.Map(document.getElementById("map"), getMapOptions(ne.latitude, ne.longitude, zoom));

        const markers = getMarkers(google, map, locations);

        const bounds = new google.maps.LatLngBounds(new google.maps.LatLng(sw.latitude, sw.longitude), new google.maps.LatLng(ne.latitude, ne.longitude));

        const historicalOverlay = CustomOverlay.getInstance(google, bounds, image, rotation);

        initMarkerClusterer(map, markers);

        historicalOverlay.setMap(map);

      })
      .catch(e => {
        console.error('error on loading maps', e);
      })

}

export default CustomMap;
