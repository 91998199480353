import customerIsBroker from "../../utils/customer";

const generateLocations = (idEnterprise, batchs) =>
  batchs?.map((item) => {
    const {
      id,
      batch,
      situation,
      description,
      paymentMethod,
      block,
      batchValue,
      size,
      latitude,
      longitude,
    } = item;
    return {
      id,
      batch,
      situation,
      description,
      paymentMethod,
      title: `Quadra: ${block}, Lote: ${batch}`,
      batchValue,
      size,
      location: {
        lat: latitude ? Number.parseFloat(latitude) : 0,
        lng: longitude ? Number.parseFloat(longitude) : 0,
      },
      redirect: customerIsBroker()
        ? { reserve: `/empreendimentos/${idEnterprise}/lotes/${id}/reservar`, simulation: `/empreendimentos/${idEnterprise}/lotes/${id}/simular` }
        : `/empreendimentos/${idEnterprise}/lotes/${id}/contatar`,
    };
  });

export default generateLocations;
