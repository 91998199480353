import axios from "axios";
import { getCurrentCustomer, updateCurrentCustomer } from "../utils/customer";
import { createBrowserHistory } from "history";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT,
});

api.interceptors.request.use(
  function (config) {
    document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  async function (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      const customer = getCurrentCustomer();
      const { refreshToken } = customer;
      try {
        const { data } = await postRefreshToken(refreshToken);
        const customerToUpdate = { ...customer, token: data };
        updateCurrentCustomer(customerToUpdate);
      } catch (error) {
        createBrowserHistory().push("/");
        sessionStorage.removeItem("customer");
      }
    }
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

const postRefreshToken = (refreshToken) => {
  return api.post("/refreshtoken", {
    refreshToken,
  });
};

api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
api.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

export default api;
