import React from "react";
import { Footer, Div, Img, P, I, Small, A } from "../generic";
import shimmaLogo from "../../img/sihmma-logo-5.png";
import { Link } from "react-router-dom";

const FooterCompoment = (props) => {
  return (
    <Footer className="footer">
      <Div className="container-fluid">
        <Div className="row text-muted">
          <Div className="col-7 text-start mt-3 display-6 social">
            <P>
              <Img
                className="img-fluid"
                style={{ width: "140px" }}
                src={shimmaLogo}
              />
            </P>
          </Div>
          <Div className="col-5 text-end mt-3 display-6 social">
            <I className="fab fa-instagram-square me-2" />
            <I className="fab fa-linkedin me-2" />
            <I className="fab fa-facebook-square me-2" />
            <I className="fab fa-whatsapp me-2" />
          </Div>
        </Div>
        <Div
          className="list-inline mt-3 display-15 social"
          style={{ color: "#999", textAlign: "left" }}
        >
          <Small>
            Desenvolvido por{" "}
            <A href="http://sihmma.com.br" style={{ color: "#999" }}>
              Sihmma Tecnologia
            </A>{" "}
            © 2021 - Todos os Direitos Reservados | <Link style={{ color: "#999" }} to={"/politica-de-privacidade"}> Políticas de Privacidade </Link>
          </Small>
        </Div>
      </Div>
    </Footer>
  );
};

export default FooterCompoment;
