import React from "react";
import Footer from "../../components/footer";
import Container from "../../components/container";
import Main from "../../components/main";
import Wrapper from "../../components/wrapper";


const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Main>
        <Container>{children}</Container>
        <Footer />
      </Main>
    </Wrapper>
  );
};

export default Layout;
