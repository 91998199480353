import styled from 'styled-components';

export const Root = styled.div`

  .form-group {
    margin-bottom: 2.5em;
  }

  .error-field{
    color: red;
    font-weight: bold;
    padding: 5px 0 0 0;
  }
`;

export const Header_Enterprise = styled.div`
  position: relative;
  height: 43px;
`