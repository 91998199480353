import React from "react";
import Footer from "../../components/footer";
import Container from "../../components/container";
import Main from "../../components/main";

const Layout = ({ children }) => {
  return (   
      <Main>
        <Container>{children}</Container>
        <Footer />
      </Main>    
  );
};

export default Layout;

/*obs: Foi necessario criar esse novo componente para que fosse usado no pages de enterprise para retirar a barra azul do Header.
Não sendo afetada as demais paginas que ficaram com essa taxa azul como está la no Home*/