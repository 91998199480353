import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getById = createAsyncThunk(
  "enterprise/getById",
  async ({ id, customerId }) => {
    const { data } = await callAPI({ method: 'GET', url: `enterprises/${id}`,
      params: { customerId },
    });
    return data;
  }
);

const INITIAL_STATE = {};

export const slice = createSlice({
  name: "currentEnterprise",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getById.fulfilled]: (state, { payload }) => {
      console.log("currentEnterprise", payload);
      const {
        batchs,
        id,
        name,
        neLatitude,
        neLongitude,
        publicity,
        rotate,
        swLatitude,
        swLongitude,
        file,
        zoom,
        publicityFiles
      } = payload;

      return {
        ...state,
        id,
        name,
        image: file.filename,
        ne: {
          latitude: Number.parseFloat(neLatitude),
          longitude: Number.parseFloat(neLongitude),
        },
        publicity,
        publicityFiles,
        rotate,
        sw: {
          latitude: Number.parseFloat(swLatitude),
          longitude: Number.parseFloat(swLongitude),
        },
        zoom: Number.parseInt(zoom),
        batchs,
      };
    },
  },
});

export default slice.reducer;
