import React from "react";
import { Div, H5 } from "../generic";
import GoogleMap from "../map";
import { CustomCard, MapContent } from "./style";

const EnterpriseMapCard = ({ id, image, sw, ne, rotate, zoom, batchs }) => {
  return (
    <Div className="col-12 col-lg-12">     
        <Div className="card-bodyMap">
          <MapContent>
            <GoogleMap
              id={id}
              image={image}
              sw={sw}
              ne={ne}
              rotation={rotate}
              batchs={batchs}
              zoom={zoom}
            />
          </MapContent>
        </Div>      
    </Div>
  );
};

export default EnterpriseMapCard;

{/*retirado o componente  <CustomCard> do mapa para que ele integre a container do customCard que se encontra os demais
 componentes da pages enterprise o qual ele está também fazendo parte. senão fosse retirado ficaria varios container um dentro do outro.
 e também foi mudado o nome da classe do map de "card-body" para "card-bodyMap" para que no style não impactasse em outras pages que ele
 colocou esse mesmo nome de classe para varios componentes*/}