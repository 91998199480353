import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getAllByCustomerId = createAsyncThunk(
  "messages-boxes/getAllByCustomerId",
  async ({customerId, page, size}) => {
    const { data } = await callAPI({method: "GET", url: `messages-boxes/${customerId}/pageable?page=${page}&size=${size}`});
    return data;
  }
);

export const getAllUnreadByCustomerId = createAsyncThunk(
  "messages-boxes/getAllUnreadByCustomerId",
  async (customerId) => {
    const { data } = await callAPI({method: "GET", url: `messages-boxes/${customerId}/unread`});
    return data;
  }
);

const INITIAL_STATE = {unread: 0, messages: []}

export const slice = createSlice({
  name: "messages-boxes",
  initialState: INITIAL_STATE,
  reducers: {
    updateToRead: (state, action) => {
      return {...state, messages: state.messages.map(message => message.id === action.payload ? {...message, unread: false} : message)}
    }
  },
  extraReducers: {
    [getAllByCustomerId.fulfilled]: (state, { payload }) => {
      return {...state, messages: payload};
    },
    [getAllUnreadByCustomerId.fulfilled]: (state, { payload }) => {
      return {...state, unread: payload};
    },
  },
});

export const {updateToRead} = slice.actions;

export default slice.reducer;
