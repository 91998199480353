import { toast } from 'react-toastify';

export const callToashSuccess = (message) => {
  toast.success(message)
}

export const callToashError = (message) => {
  toast.error(message)
}

