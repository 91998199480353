const dayjs = require("dayjs");

const DELIMITER = "/";

const formatDate = (date) => {
  return date ? dayjs(date).format("DD/MM/YYYY") : "";
};

export const formatDateWithPattern = (date, pattern) => {
  return date ? dayjs(date).format(pattern) : "";
};

export const getTheLast10Years = () => {
  const currentYear = new Date().getFullYear();
  const last10Years = [];
  for (let index = 10; index >= 0; index--) {
    last10Years.push(currentYear - index);
  }
  return last10Years;
};

export const getDateAsString = (dateAsString) => {
  const dateArray = dateAsString?.split(DELIMITER);
  return dateArray
    ? new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
    : undefined;
};

export default formatDate;
