import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getCompanyInformation = createAsyncThunk(
  "companyInformation/getCompanyInformation",
  async () => {
    const { data } = await callAPI({
      method: "GET",
      url: `company-information`,
    });
    return data;
  }
);

const INITIAL_STATE = { banner: "", logo: "" };

export const companyInformationSlice = createSlice({
  name: "companyInformation",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getCompanyInformation.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export default companyInformationSlice.reducer;
