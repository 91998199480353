 import MarkerClusterer from '@google/markerclusterer'

  var getGoogleClusterInlineSvg = function (color) {
        var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');

        return ('data:image/svg+xml;base64,' + encoded);
    };

var cluster_styles = [
        {
            width: 40,
            height: 40,
            url: getGoogleClusterInlineSvg('blue'),
            textColor: 'white',
            textSize: 12
        }
    ];

 const initMarkerClusterer = (map, markers) =>
    new MarkerClusterer  (map, markers, {
      maxZoom: 17,
      styles: cluster_styles
    });

export default initMarkerClusterer;
