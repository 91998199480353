import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getTaxpayerNearestByDescription = createAsyncThunk(
  "taxpayers/getTaxpayerNearestByName",
  async () => {
    const { data } = await callAPI({ method: 'GET', url: "taxpayertypes/"});
    return data;
  }
);

const INITIAL_STATE = [{ label: "", id: undefined }];

export const taxpayerSlice = createSlice({
  name: "taxpayers",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getTaxpayerNearestByDescription.fulfilled]: (state, { payload }) => {
      return payload.map((profession) => {
        return {
          label: profession.description,
          id: profession.id,
        };
      });
    },
  },
});

export default taxpayerSlice.reducer;
