import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getCitiesNearestByName = createAsyncThunk(
  "cities/getCitiesNearestByName",
  async (nearestName) => {
    const { data } = await callAPI({ method: 'GET', url: `cities/nearest/${nearestName}`});
    return data;
  }
);

const INITIAL_STATE = [{ label: "", id: undefined }];

export const citiesSlice = createSlice({
  name: "cities",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getCitiesNearestByName.fulfilled]: (state, { payload }) => {
      return payload.map((city) => {
        return { label: `${city.name} - ${city.uf}`, id: city.id };
      });
    },
  },
});

export default citiesSlice.reducer;
