const CUSTOMER_KEY = "customer"

const customerIsBroker = () => {
  const customer = getCurrentCustomer();
  if (customer) {
    const { broker } = customer;
    return broker;
  }
  return false;
};

export const getCurrentCustomer = () => {
  return JSON.parse(sessionStorage.getItem(CUSTOMER_KEY));
};

export const getTokenCurrentCustomer = () => {
  return getCurrentCustomer()?.token;
}

export const getTokenSihmma = () => {
  return getCurrentCustomer()?.control;
}

export const customerIsLogged = () => {
  return getCurrentCustomer() ? true : false;
};

export const updateCurrentCustomer = (customerToUpdate) => {
  const customer = getCurrentCustomer();
  const customerUpdated = { ...customer, ...customerToUpdate }
  sessionStorage.setItem(CUSTOMER_KEY, JSON.stringify(customerUpdated))
}

export default customerIsBroker;
