import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getAllUfs = createAsyncThunk("taxpayers/getAllUfs", async () => {
  const { data } = await callAPI({ method: 'GET', url: "ufs/"});
  return data;
});

const INITIAL_STATE = [{ label: "", id: undefined }];

export const ufsSlice = createSlice({
  name: "taxpayers",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getAllUfs.fulfilled]: (state, { payload }) => {
      return payload.map((profession) => {
        return {
          label: profession.name,
          id: profession.id,
        };
      });
    },
  },
});

export default ufsSlice.reducer;
