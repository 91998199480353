import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Nome deve ter mais de 3 caracteres!")
    .required("Nome é requerido!"),
  email: Yup.string()
    .email("Formato de email não é válido!")
    .required("Email é requerido!"),
  phone: Yup.string().required("Telefone é requerido!"),
});

export default schema;
