import { isUndefined } from "lodash-es";
import { findById } from "../../../store/ducks/batch";
import customerIsBroker, { getCurrentCustomer, customerIsLogged } from "../../customer";

const iconScale = 0.6;

const contentInfoWindow = ({ block, batch, size, paymentMethod, redirect, situation }) => `
  <div class="modal-container">
    <div class="row">
      <div class="col">
        <h5>Quadra: ${block} Lote: ${batch}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">

      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
       Tamanho: ${size} m²
      </div>
    </div>
    ${
      isUndefined(paymentMethod)
        ? `<Div />`
        : `<div class="row">
      <div class="col">
       Forma de pagamento:
       </div>
       <span>${
         isUndefined(paymentMethod) ? "" : paymentMethod.split("+")[0]
       }</span>
       <span>${
         isUndefined(paymentMethod) ? "" : paymentMethod.split("+")[1]
       }</span>
    </div>`
    }

    <div class="row">
      <div class="col">
      </div>
    </div>
     <div class="row">
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
    </div>
    <div class="row ">
      ${customerIsBroker() && ( situation !== 'FREE' && situation !== 'RESERVED') ?
      `<div/>` : situation === 'CUSTOMER' ? `<div/>` :
      `<div class="col-3 modal-buttons">
        <a class="btn btn-primary" target="_self" href=${customerIsBroker() ? redirect.reserve : redirect}> ${
        customerIsBroker() ? `Reservar Lote` : `Quero informação`
      } </a>
        ${customerIsBroker() ? `<a class="btn btn-primary" target="_self" href=${customerIsBroker() && redirect.simulation}>
         Simulação
       </a>` : ''}
      </div>`
    }
      </div>
    </div>
  </div>
`;

const contentInfoWindowUnavaible = () => `
  <div class="container-fluid modal-unavailable" style="width:278px" >
    <div class="row">
      <div class="col">
        <h3>Desculpe esse emprendimento não está disponível para venda no momento</h3>
      </div>
    </div>
  </div>
`;

const getMarkers = (google, map, batchs) => {
  const infoWindow = new google.maps.InfoWindow();

  const iconGreen = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "#00ff77",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const iconYellow = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "yellow",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const iconRed = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "red",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const iconGrey = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "grey",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const iconBlack = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "black",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const iconBlue = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "blue",
    fillOpacity: 0.6,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 0,
    scale: iconScale,
  };

  const getInfoWindowContent = async (item) => {
    const customer = getCurrentCustomer();
    const updatedItem = await findById({
      id: item.id,
      customerId: customer?.customerId,
    });

    if (!customerIsBroker()) {
      delete updatedItem["paymentMethod"];
    }
    return contentInfoWindow({ ...item, ...updatedItem });
  };

  const markers = batchs.map((item) => {
    const { batch, location, situation } = item;
    const marker = new google.maps.Marker({
      position: location,
      label: { color: "white", text: batch },
      icon:
        situation === "FREE"
          ? iconGreen
          : situation === "PULBIC"
          ? iconBlack
          : situation === "CUSTOMER"
          ? iconBlue
          : situation === "RESERVED"
          ? iconYellow
          : situation === "SOLD"
          ? iconRed
          : situation === "UNAVAILABLE"
          ? iconGrey
          : iconBlack,
    });

    marker.addListener("click", async () => {
      const content = await getInfoWindowContent(item);
      infoWindow.setContent(content);
      infoWindow.open(map, marker);
    });

    return marker;
  });

  return markers;
};

export default getMarkers;
