import { isUndefined, isNull } from "lodash-es";
import api from "../../api/axios";

const dayjs = require("dayjs");

const encode = async (token) => {
  if (isNull(token) || isUndefined(token)) {
    return null;
  } else {
    const { data } = await api.get("/date-server");
    const currentDate =  data?.DataServidor
    const dateFormated = formatDate(currentDate);
    const base64 = encodeToBase64(dateFormated);
    const startPosition = getStartPosition(currentDate);
    const newToken = generetedNewToken(token, startPosition, base64);
    return newToken;
  }
};

const encodeToBase64 = (text) => {
  return Buffer.from(text, "utf-8").toString("base64");
};

const formatDate = (date) => {
  return dayjs(date).format("ssYYYYmmDDMMHH");
};

const generetedNewToken = (token, start, base64) => {
  var tokenChanged =
    token.substring(0, token.length - 10) +
    start +
    base64.length +
    token.substring(token.length - 10);

  var newToken = tokenChanged.substring(0, start);
  tokenChanged = tokenChanged.substring(start);
  var text = "";
  for (let index = 0; index < base64.length; index++) {
    text = tokenChanged.substring(0, 2);
    tokenChanged = tokenChanged.substring(2);
    newToken += base64.substring(index, index +1) + text;
  }
  return newToken + tokenChanged;
};

const getStartPosition = (currentDate) => {
  return new Date(currentDate).getHours() % 2 !== 0 ? 30 : 50;
};

export default encode;
