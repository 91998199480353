import React, { createRef, useEffect } from "react";
import CustomMap from "../../utils/google-maps";
import generateLocations from "./locations";

const GoogleMap = ({ id, image, sw, ne, rotation, batchs, zoom }) => {
  const googleMapRef = createRef(id);

  useEffect(() => {
    const locations = generateLocations(id, batchs);

    async function loadMap() {
      await CustomMap.getInstance(image, sw, ne, rotation, zoom, locations);
    }

    if ((image, sw, ne, rotation, zoom, locations)) {
      loadMap();
    }
  }, [image, sw, ne, rotation, batchs, zoom, id]);

  return (
    <div
      id="map"
      ref={googleMapRef}
      style={{ width: "100%", height: "900px" }}
    />
  );
};

export default GoogleMap;
