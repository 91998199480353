import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isUndefined } from "lodash-es";
import callAPI from "../../../api";

export const getCustomersNearestByName = createAsyncThunk(
  "customers/getCstomersNearestByName",
  async (name) => {
    const { data } = await callAPI({method: 'GET', url:`customers/nearest/${name}`});
    return data;
  }
);

export const getCustomerLocalizationsByCustomerId = createAsyncThunk(
  "customer/getCustomerLocalizationsByCustomerId"
);

const INITIAL_STATE = [{ label: "", id: undefined }];

export const customersSclice = createSlice({
  name: "customers",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getCustomersNearestByName.fulfilled]: (state, { payload }) => {
      return payload.map(({ cpf, name, cnpj, id }) => {
        return {
          label: `${name} ${isUndefined(cpf) ? "" : cpf} ${
            isUndefined(cnpj) ? "" : cnpj
          } `,
          id,
        };
      });
    },
  },
});

export default customersSclice.reducer;
