import api from './axios'

import { getTokenCurrentCustomer } from '../utils/customer'
import encode from '../utils/token'

const callAPI = async (options) => {

  var headers = {};
  const tokenCurrentCustomer = getTokenCurrentCustomer();
    if (tokenCurrentCustomer) {
      headers = {...headers, 'Authorization': `Bearer ${await encode(tokenCurrentCustomer)}`}
    }

    const optionsUpdated = {...options, headers};

    return api(optionsUpdated);
}

export default callAPI;
