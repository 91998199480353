import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import PublicRoute from "./../components/public-route";
import PrivateRoute from "./../components/private-route";
import EnterpriseDetails from "../pages/enterprise-details";
import EnterpriseMaps from "../pages/enterprise-map";
import Contact from "../pages/contact";

const Home = lazy(() => import("../pages/home"));
const Enterprise = lazy(() => import("../pages/enterprise"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Profile = lazy(() => import("../pages/profile"));
const Contracts = lazy(() => import("../pages/contracts"));
const ContractDetailPage = lazy(() => import("../pages/contract-detail"));
const ResetPassword = lazy(() => import("../pages/reset-password"));
const Reserve = lazy(() => import("../pages/reserve"));
const Simulation = lazy(() => import("../pages/simulation"));
const MessageBox = lazy(() => import("../pages/message-box"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));

const RoutersApp = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<span> carregando </span>}>
        <Switch>
          <PublicRoute exact path="/" component={Home} />
          <PublicRoute
            exact
            path="/empreendimentos/:id"
            component={Enterprise}
          />
          <PublicRoute
            exact
            path="/empreendimentos/:id/detalhes"
            component={EnterpriseDetails}
          />
          <PublicRoute
            exact
            path="/empreendimentomap/:id"
            component={EnterpriseMaps}
          />
          <PublicRoute
            exact
            path="/empreendimentos/:id/lotes/:idBatch/contatar"
            component={Contact}
          />
          <PublicRoute
            exact
            path="/empreendimentos/:id/lotes/:idBatch/reservar"
            component={Reserve}
          />
          <PublicRoute
            exact
            path="/empreendimentos/:id/lotes/:idBatch/simular"
            component={Simulation}
          />
          <PublicRoute
            exact
            path="/politica-de-privacidade"
            component={PrivacyPolicy}
          />
          <PrivateRoute exact path="/inicio" component={Dashboard} />
          <PrivateRoute exact path="/perfil" component={Profile} />
          <PrivateRoute exact path="/caixa-de-entrada" component={MessageBox} />
          <PrivateRoute exact path="/contratos" component={Contracts} />
          <PrivateRoute
            exact={true}
            path="/contratos/:idContrato/detalhes"
            component={ContractDetailPage}
          />
          <PrivateRoute
            exact
            path="/redefinir-senha"
            component={ResetPassword}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default RoutersApp;
