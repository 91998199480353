import React from "react";
import ReactDOM from "react-dom";
import * as themes from "./theme/schema.json";
import { setToLocalStorage } from "./utils/storage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from "./App";

const Index = () => {
  setToLocalStorage("all-themes", themes.default);
  return <App />;
};

ReactDOM.render(<Index />, document.getElementById("root"));
