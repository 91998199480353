import React from "react";
import { Main, Div } from "../generic";

const Container = ({ children }) => {
  return (
    <Main className="content">
      <Div className="container-fluid">{children}</Div>
    </Main>
  );
};

export default Container;
