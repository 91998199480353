import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";
import formatErrorMessage from "../../../utils/message-error";

export const findById = async ({ id, customerId }) => {
  if (customerId) {
    const { data } = await callAPI({ method: 'GET', url:
      `batches/${id}/details?customerId=${customerId}`}
    );
    return data;
  } else {
    const { data } = await callAPI({ method: 'GET', url: `batches/${id}/details`});
    return data;
  }
};

export const getById = createAsyncThunk("batches/getById", findById);

export const sendIntention = async (payload) => {
  return await callAPI({ method: 'POST', url: `batches/intentions`, data: payload});
};

export const sendReserve = async (payload) => {
  return await callAPI({ method: 'POST', url: `batches/reserve`, data: payload});
};

export const sendSimulation = async (payload) => {
  return await callAPI({ method: 'POST', url: `batches/simulate`, data: payload});
};

export const saveIntention = createAsyncThunk(
  "batches/saveIntention",
  async ({ payload, callbackError, callbackSuccess }) => {
    try {
      const { data } = await sendIntention(payload);
      callbackSuccess();
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const saveReserve = createAsyncThunk(
  "batches/saveReserve",
  async ({ payload, callbackError, callbackSuccess }) => {
    try {
      const { data } = await sendReserve(payload);
      callbackSuccess();
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

export const simulate = createAsyncThunk(
  "batches/simulate",
  async ({ payload, callbackError, callbackSuccess }) => {
    try {
      const { data } = await sendSimulation(payload);
      callbackSuccess(data);
      return data;
    } catch (error) {
      callbackError()(formatErrorMessage(error));
      throw error;
    }
  }
);

const INITIAL_STATE = {};

export const slice = createSlice({
  name: "currentBatch",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getById.fulfilled]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [saveIntention.fulfilled]: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export default slice.reducer;
