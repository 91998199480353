const CustomOverlay = {

  getInstance: (google, bounds, image, rotation) => {

    class CustomOverlay extends google.maps.OverlayView {
      bounds;
      image;
      div;

      constructor(bounds, image) {
        super();
        this.bounds = bounds;
        this.image = image;
      }

      onAdd() {
        this.div = document.createElement("div");
        this.div.style.position = "absolute";
        this.div.style.transform = `rotate(${rotation}deg)`
        const img = document.createElement("img");
        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      }
      draw() {
        const overlayProjection = this.getProjection();

        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest()
        );

        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast()
        );

        if (this.div) {
          this.div.style.left = sw.x + "px";
          this.div.style.top = ne.y + "px";
          this.div.style.width = ne.x - sw.x + "px";
          this.div.style.height = sw.y - ne.y + "px";
        }
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          delete this.div;
        }
      }

      hide() {
        if (this.div) {
          this.div.style.visibility = "hidden";
        }
      }

      show() {
        if (this.div) {
          this.div.style.visibility = "visible";
        }
      }

      toggle() {
        if (this.div) {
          if (this.div.style.visibility === "hidden") {
            this.show();
          } else {
            this.hide();
          }
        }
      }

      toggleDOM(map) {
        if (this.getMap()) {
          this.setMap(null);
        } else {
          this.setMap(map);
        }
      }

    }

    return new CustomOverlay(bounds, image);
  }
};

export default CustomOverlay;
