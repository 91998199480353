import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form as FormByFormik, Field } from "formik";
import { Form, Container, Row, Col, Card, Modal } from "react-bootstrap";
import ExternalLayout_Enterprise from "../../layouts/ExternalLayout-Enterprise";
import { getById, saveIntention } from "../../store/ducks/batch";
import { BR, Button, Div, H1, H2, H3, I, P, Span } from "../../components/generic";
import validationSchema from "./validationSchema";
import useGetCustomer from "../../hooks/customer";
import { Root, Header_Enterprise } from "./style";
import CustomContainer from "../../components/custom-container";
import Contents from "../../components/contents";
import LabelMandatory from "../../components/label-mandatory";
import {formatDateWithPattern} from "../../utils/date"
import { callToashError, callToashSuccess } from "../../utils/toash";
import "./style.css";


const buildInitalValues = (name, phone, email, cpfCnpj) => {
  return {
    name,
    cpfCnpj,
    email,
    phone,
  };
};

const handleSuccessOnSave = (history, enterpriseId) => () => {
  history.push(`/empreendimentos/${enterpriseId}`);
  callToashSuccess("Contato salvo com sucesso !")
};

const handleErrorOnSave =
  () => () => {
    return callToashError;
  };

const handleSave = (dispatch, values, callbackSuccess, callbackError) => {
  dispatch(saveIntention({ payload: {...values, dateIntetion: formatDateWithPattern(new Date(), "YYYY-MM-DD HH:mm:ss")}, callbackError, callbackSuccess }));
};

const Contact = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { name, phone, email, cpfCnpj, userName } = useGetCustomer();
  const { idBatch, id: enterpriseId } = useParams();

  const { id, block, batch, description, paymentFormId, priceTableItemId } =
    useSelector((state) => {
      return state.batch;
    });

  useEffect(() => {
    const getBatch = () => {
      dispatch(getById({ id: idBatch }));
    };

    getBatch();
  }, [dispatch]);

  return (
    <ExternalLayout_Enterprise>      
      <Header_Enterprise>
        <Row>
              <Col
                xs={12}
                className="d-flex justify-content-end align-items-center"
                >
                <Button onClick={history.goBack} className="btn new-header__button-login link-primary link--norma btn-header"                
                >
                  <i className="align-self-center me-2"></i>
                  Voltar
                </Button>
              </Col>
            </Row>
      </Header_Enterprise>
      <Row>
        <Contents>
          <Card className="card-Contato">
            <Card.Body className="cardBody-Contato">
              <div className="row text-Titulo">
                <Col xs={12} className="d-flex justify-content-start align-items-center">
                  <P>
                    <H1 className="header-title text-Contato">
                      Quadra: {block} Lote: {batch}
                    </H1>
                  </P>
                  <P className="header-subtitle">{description}</P>
                </Col>
              </div>
              <Container>
                <Row>
                  <Card.Title>                    
                    <H3 className="card-subtitle mb-2 text-Subtitulo">
                      Envie as informações abaixo que entramos em contato,
                      para sua comodidade.
                    </H3>
                  </Card.Title>
                </Row>
                <Col xs="12" style={{ width: "98.5%" }}>
                </Col>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={buildInitalValues(
                    name,
                    phone,
                    email,
                    cpfCnpj
                  )}
                  onSubmit={(values) => {
                    handleSave(
                      dispatch,
                      {
                        ...values,
                        enterpriseBatchId: id,
                        priceTableItemId,
                        paymentFormId,
                        userName,
                      },
                      handleSuccessOnSave(history, enterpriseId),
                      handleErrorOnSave()
                    );
                  }}
                >
                  {({ errors, touched }) => (
                        <FormByFormik className="FormContato">
                          <Field
                            name="name"
                            render={({ field }) => (
                              <Form.Group className="mb-3" controlId="name">
                                <LabelMandatory>Nome* :</LabelMandatory>
                                <Form.Control
                                  type="text"
                                  onChange={field.onChange}
                                  value={field.value}
                                  placeholder="Nome completo"
                                />
                                {errors.name && touched.name ? (
                                  <Span>{errors.name}</Span>
                                ) : null}
                              </Form.Group>
                            )}
                          />
                          <Field
                            name="email"
                            render={({ field }) => (
                              <Form.Group
                                className="mb-3"
                                controlId="email"
                              >
                                <LabelMandatory>Email* :</LabelMandatory>
                                <Form.Control
                                  type="email"
                                  onChange={field.onChange}
                                  value={field.value}
                                  placeholder="seuemail@dominio.com"
                                />
                                {errors.email && touched.email ? (
                                  <Span>{errors.email}</Span>
                                ) : null}
                              </Form.Group>
                            )}
                          />
                          <Field
                            name="phone"
                            render={({ field }) => (
                              <Form.Group
                                className="mb-3"
                                controlId="phone"
                              >
                                <LabelMandatory>Telefone* :</LabelMandatory>
                                <Form.Control
                                  type="text"
                                  onChange={field.onChange}
                                  value={field.value}
                                  placeholder="Telefone"
                                />
                                {errors.phone && touched.phone ? (
                                  <Span>{errors.phone}</Span>
                                ) : null}
                              </Form.Group>
                            )}
                          />
                          <Row className="justify-content-md-end">
                            <Col xs="12"></Col>
                            <Col xs="1" md="auto">
                              <Button
                                type="submit"
                                className="btn btn-primary"
                              >
                                Enviar
                              </Button>
                            </Col>
                          </Row>
                        </FormByFormik>
                  )}
                </Formik>
              </Container>
            </Card.Body>
          </Card>
        </Contents>
      </Row>
    </ExternalLayout_Enterprise>
  );
};

export default Contact;
