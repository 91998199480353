import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callAPI from "../../../api";

export const getAll = createAsyncThunk("enterprise/getAll", async () => {
  const { data } = await callAPI({ method: "GET", url: `enterprises` });
  return data;
});

const INITIAL_STATE = [];

export const slice = createSlice({
  name: "enterprises",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getAll.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export default slice.reducer;
