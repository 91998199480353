import styled from "styled-components";

export const Div = styled.div``;

export const Button = styled.button``;

export const Nav = styled.nav``;

export const Img = styled.img``;

export const Small = styled.small``;

export const Ul = styled.ul``;

export const Li = styled.li``;

export const Ol = styled.ol``;

export const Span = styled.span``;

export const Form = styled.form``;

export const Input = styled.input``;

export const Svg = styled.svg``;

export const P = styled.p``;

export const I = styled.i``;

export const H1 = styled.h1``;

export const H2 = styled.h2``;

export const H3 = styled.h3``;

export const H4 = styled.h4``;

export const H5 = styled.h5``;

export const Main = styled.main``;

export const Footer = styled.footer``;

export const A = styled.a``;

export const Grid = styled.div``;

export const Table = styled.table``;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const Td = styled.td``;

export const TextArea = styled.textarea``;

export const Label = styled.label``;

export const Select = styled.select``;

export const Option = styled.option``;

export const Strong = styled.strong``;

export const BR = styled.br``;
